<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<Message v-if="loading" message="loading" type="info" />

		<template v-else>
			<Card
				v-for="name of Object.keys(groups)"
				:key="name"
			>
				<template #header>
					{{ name }}
				</template>

				<template #default>
					<ul>
						<li v-for="serviceName of keys(groups[name])" :key="serviceName">
							{{ serviceName }}
							<ul>
								<li v-for="group of groups[name][serviceName]" :key="group">
									{{ group }}
								</li>
							</ul>
						</li>
					</ul>
				</template>

				<template #footer>
					<Button
						class="button"
						@click="() => edit(name)"
					>
						Edit
					</Button>

					<Button
						v-if="!groups[name].static"
						class="button delete"
						@click="() => deleteGroup(name)"
					>
						Delete
					</Button>
				</template>
			</Card>

			<div class="navigation">
				<Button @click="back">
					Back
				</Button>

				<Button @click="create">
					Create
				</Button>
			</div>
		</template>
	</div>
</template>

<script>
import api from "@/api";

import Card 	from "@/components/Card.vue";
import Button	from "@/components/Button.vue";
import Message	from "@/components/Message.vue";

export default {
	name: "Groups",

	components: {
		Card,
		Button,
		Message
	},

	data () {
		return {
			error: undefined,
			loading: true,

			groups: {}
		};
	},

	async beforeMount () {
		await this.update();
	},

	methods: {
		async update () {
			this.error = undefined;

			try {
				this.groups = await api.getGroups();
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			this.loading = false;
		},

		keys (obj) {
			const newObj = {...obj};

			delete newObj.static;

			return Object.keys(newObj);
		},

		edit (name) {
			this.$router.push({
				name: "Group",
				params: { name }
			});
		},

		create () {
			this.$router.push({
				name: "Group"
			});
		},

		async deleteGroup (name) {
			try {
				this.loading = true;
				await api.deleteGroup(name);
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			await this.update();
		},

		back () {
			this.$router.push({
				name: "Admin"
			});
		}
	}
};
</script>

<style scoped>
.button {
	width: 38%;
	margin-left: auto;
	margin-right: auto;
}

.delete {
	color: white;
	background-color: #f66;
}

.delete:hover {
	color: white;
	background-color: #ff8080 !important;
}

.navigation {
	display: flex;
}

.navigation > * {
	width: 40%;
	margin: auto;
}
</style>
